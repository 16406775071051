import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic/linkResolver'
import get from 'lodash/get'
import CaseTeaser from '../components/CaseTeaser'
import CategoryHeader from '../components/CategoryHeader'
import CasesGrid from '../components/CasesGrid'

export const categoryQuery = graphql`
  query QueryCategory($id: String, $uid: String, $lang: String){
    prismic {
      allCases(sortBy: rank_DESC, where: {category: $id}) {
        edges {
          node {
            title
            featured_image_portrait
            featured_detail_image
            collections {
              collection {
                ... on PRISMIC_Collection {
                  title
                }
              }
            }
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
      allCategorys(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            description
            _meta {
              type
              uid
              lang
            }
          }
        }
      }
    }
  }
`

const Category = ({data}) => {
  const category = get(data, 'prismic.allCategorys.edges[0]')
  const categoryLang = get(category, 'node._meta.lang')
  const cases = get(data, 'prismic.allCases.edges')
  const title = get(category,'node.title[0].text')
  let description = get(category,'node.description')
  if(description){
    description = RichText.render(description, linkResolver)
  }
  const cols = []

  cols.push(<CategoryHeader title={title} description={description} inspirationMeta={{lang: categoryLang}} />)

  cases.forEach((caseData, index)=>{
    const node = get(caseData,'node')
    const title = get(node, 'title[0].text')
    const prismicImage = get(node,'featured_image_portrait')
    const detailPrismicImage = get(node,'featured_detail_image')
    const meta = get(node,'_meta')
    const collections = get(node, 'collections')

    cols.push(<CaseTeaser title={title} prismicImage={prismicImage} detailPrismicImage={detailPrismicImage} meta={meta} key={index} collections={collections} />)
  })

  return <CasesGrid cols={cols} />
}

Category.query = categoryQuery;

export default Category;